import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";

const SignUpForm = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptedTerms: false,
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    const {
      firstName,
      lastName,
      username,
      email,
      password,
      confirmPassword,
      acceptedTerms,
    } = formData;

    // Validations
    if (password.length < 8) {
      setError("Şifreniz en az 8 karakter olmalıdır.");
      setIsLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      setError("Şifreler eşleşmiyor.");
      setIsLoading(false);
      return;
    }
    if (!acceptedTerms) {
      setError("Kullanım sözleşmesini kabul etmelisiniz.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://api.psmmag.com/api/auth/register",
        {
          name: `${firstName} ${lastName}`,
          username,
          email,
          password,
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success(response.data.message);
        // Simulating login after successful registration
        await login(response.data.token || "dummy-token");

        // Close the modal
        const modal = document.getElementById("login-modal");
        if (modal && typeof window !== "undefined" && window.bootstrap) {
          const bootstrapModal = window.bootstrap.Modal.getInstance(modal);
          if (bootstrapModal) {
            bootstrapModal.hide();
          }
        }

        // Clear form fields and reload
        setFormData({
          firstName: "",
          lastName: "",
          username: "",
          email: "",
          password: "",
          confirmPassword: "",
          acceptedTerms: false,
        });

        // window.location.reload();
      } else {
        setError(response.data.message || "Kayıt işlemi başarısız oldu.");
      }
    } catch (error) {
      console.error("Registration failed:", error);
      setError("Kayıt sırasında bir hata oluştu. Lütfen tekrar deneyin.");
      // window.location.reload();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="leading-8">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="firstName">Adınız</label>
          <br />
          <input
            type="text"
            id="firstName"
            name="firstName"
            className="login-modal-input"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="lastName">Soyadınız</label>
          <br />
          <input
            type="text"
            id="lastName"
            name="lastName"
            className="login-modal-input"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <label htmlFor="username">Kullanıcı Adınız</label>
      <br />
      <input
        type="text"
        id="username"
        name="username"
        className="login-modal-input"
        value={formData.username}
        onChange={handleChange}
        required
      />
      <br />
      <label htmlFor="email">E-posta Adresiniz</label>
      <br />
      <input
        type="email"
        id="email"
        name="email"
        className="login-modal-input"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <br />
      <label htmlFor="password">Şifreniz</label>
      <br />
      <input
        type="password"
        id="password"
        name="password"
        className="login-modal-input pr-10"
        value={formData.password}
        onChange={handleChange}
        required
      />
      <br />
      <label htmlFor="confirmPassword">Şifreniz (Tekrar)</label>
      <br />
      <input
        type="password"
        id="confirmPassword"
        name="confirmPassword"
        className="login-modal-input"
        value={formData.confirmPassword}
        onChange={handleChange}
        required
      />
      <br />
      {/* {error && <p style={{ color: "red" }}>{error}</p>} */}

      <div className="form-check flex items-center">
        <input
          className="form-check-input login-modal-check"
          type="checkbox"
          id="acceptTerms"
          name="acceptedTerms"
          checked={formData.acceptedTerms}
          onChange={handleChange}
        />
        &nbsp; &nbsp;
        <label className="form-check-label text-base" htmlFor="acceptTerms">
          <a href="" className="text-red-500 font-semibold">
            Kullanım sözleşmesini
          </a>{" "}
          okudum ve kabul ediyorum.
        </label>
      </div>

      <button
        className="login-modal-submit-btn"
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? "Kaydediliyor..." : "Üye Kaydını Tamamla"}
      </button>
    </form>
  );
};

export default SignUpForm;
