import React, { useState, useEffect } from 'react';
import "./categoryPage.css";
import { Link } from 'react-router-dom';
import SideMenu from "../../components/sideMenu/SideMenu";
import TitleBar from "../../components/titleBar/TitleBar";


const VideoCategory = () => {
    const [videoItems, setVideoItems] = useState([]);
    const [visibleItems, setVisibleItems] = useState(18);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.psmmag.com/news/video-news');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (Array.isArray(data)) {
                    setVideoItems(data.filter(item => item.isVideoNews));
                } else {
                    throw new Error('Unexpected data structure');
                }
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const showMoreItems = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + 9);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className='category-page-container'>
            <div className='category-page-top-container md:grid grid-cols-11 md:px-60'>
                <div className='category-page-top-left-container col-span-7 md:pr-[30px]'>
                <div className="mb-4 md:hidden"><TitleBar title="Video Galeri" /></div>
                    <div className="category-news-grid-container">
                        <div className="category-news-grid2">
                            {videoItems.slice(0, visibleItems).map((item) => (
                                <div key={item._id} className="category-news-item">
                                    <Link to={`/news/${item.slug}`} className="cardImgLink">
                                        <img src={item.image} alt={item.title} className="card-img-top w-100" />
                                        <div className="play-icon"><span class="material-symbols-outlined text-5xl">
play_circle
</span></div>
                                        <div className="category-news-content">
                                            <h3 className="title">{item.title}</h3>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                    {visibleItems < videoItems.length && (
                        <div className='text-center'>
                            <button onClick={showMoreItems} className="load-more-button category-page-load-more-button">
                                Daha Fazla Haber Yükle
                            </button>
                        </div>
                    )}
                </div>

                <div className='category-page-top-right-container col-span-4 md:pl-[30px]'>
                    <SideMenu />
                </div>
            </div>
        </div>
    );
}

export default VideoCategory;