import React, { useState, useEffect } from 'react';
import "./adsmobile.css";

const AdsMobile = () => {
  const [slidesData, setSlidesData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = slidesData.length;

  useEffect(() => {
    // Fetch data from API
    fetch("https://api.psmmag.com/api/ads")
      .then(response => response.json())
      .then(data => {
        // Filter based on title and isActive status
        const filteredSlides = data.filter(ad =>
          (ad.title.includes("MOBİL YATAY-1") || ad.title.includes("MOBİL YATAY-2")) &&
          ad.isActive === true
        );
        setSlidesData(filteredSlides);
      })
      .catch(error => {
        console.error("Error fetching advertisement data:", error);
      });
  }, []);

  useEffect(() => {
    if (totalSlides === 0) return; // Do not perform transitions if no data
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % totalSlides);
    }, 5000); // Transition every 5 seconds

    return () => clearInterval(interval); // Cleanup
  }, [totalSlides]);

  const handleAdClick = async (adId) => {
    if (adId) {
      try {
        await fetch(`https://api.psmmag.com/api/ads/${adId}/hit`, {
          method: "POST",
        });
      } catch (error) {
        console.error("Error incrementing hit count:", error);
      }
    }
  };

  // If there are no active ads, don't render anything
  if (totalSlides === 0) {
    return null;
  }

  return (
    <div className='mobile-ads-container'>
      <div className="slider">
        <div
          className="slider-container"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slidesData.map((slide) => (
            <div
              className="slide"
              key={slide._id}
            >
              {slide.imageURL.includes("sadbundle") ? (
                <iframe
                  src={slide.imageURL}
                  width="100%"
                  height="120px"
                  frameBorder="0"
                  scrolling="no"
                  allowFullScreen={true}
                />
              ) : (
                <a
                  href={slide.link || "#"}
                  className="slideImg"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleAdClick(slide._id)}
                >
                  <img src={slide.imageURL} alt={`Slide ${slide.title}`} />
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdsMobile;