import React from 'react'

const TitleBar = ({title}) => {
  return (
    <div className="w-full shadow-md">
      <div className=""></div>
      <div className="bg-[#C90913] text-center py-2 ">
        <p className="text-white text-xl font-bold tracking-wide">{title}</p>
      </div>
    </div>
  )
}

export default TitleBar