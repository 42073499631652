import React, { useState, useEffect } from 'react';
import "./navbar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faCloudSun, faCloud, faCloudRain, faSnowflake, faWind } from '@fortawesome/free-solid-svg-icons';

const WeatherWidget = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('İstanbul');
  const [weatherData, setWeatherData] = useState(null);
  const [weatherIcon, setWeatherIcon] = useState(null);
  const [error, setError] = useState(null);

    const WEATHER_API_KEY = "c09aaffbb0c1063cc0f3ca3f4ab88c22";

  const cities = [
    "Adana", "Adıyaman", "Afyonkarahisar", "Ağrı", "Amasya", "Ankara", "Antalya",
    "Artvin", "Aydın", "Balıkesir", "Bilecik", "Bingöl", "Bitlis", "Bolu", "Burdur",
    "Bursa", "Çanakkale", "Çankırı", "Çorum", "Denizli", "Diyarbakır", "Edirne", "Elazığ",
    "Erzincan", "Erzurum", "Eskişehir", "Gaziantep", "Giresun", "Gümüşhane", "Hakkâri",
    "Hatay", "Isparta", "Mersin", "İstanbul", "İzmir", "Kars", "Kastamonu", "Kayseri",
    "Kırklareli", "Kırşehir", "Kocaeli", "Konya", "Kütahya", "Malatya", "Manisa",
    "Kahramanmaraş", "Mardin", "Muğla", "Muş", "Nevşehir", "Niğde", "Ordu", "Rize",
    "Sakarya", "Samsun", "Siirt", "Sinop", "Sivas", "Tekirdağ", "Tokat", "Trabzon",
    "Tunceli", "Şanlıurfa", "Uşak", "Van", "Yozgat", "Zonguldak", "Aksaray", "Bayburt",
    "Karaman", "Kırıkkale", "Batman", "Şırnak", "Bartın", "Ardahan", "Iğdır", "Yalova",
    "Karabük", "Kilis", "Osmaniye", "Düzce"
  ];

  useEffect(() => {
    setFilteredCities(cities);
    getWeather('İstanbul'); 
  }, []);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setFilteredCities(
      cities.filter((city) =>
        city.toLocaleLowerCase("tr").includes(term.toLocaleLowerCase("tr"))
      )
    );
  };

  const getWeather = async (cityName) => {
    setSelectedCity(cityName);
    setError(null);

    try {
      const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${WEATHER_API_KEY}&units=metric`);
      if (!response.ok) {
        throw new Error('Error');
      }
      const data = await response.json();
      setWeatherData(data);
      updateWeatherIcon(data.weather[0].description);
    } catch (error) {
      console.error('Error fetching weather data:', error);
      setError('Error');
    }
  };

  const updateWeatherIcon = (description) => {
    switch (description.toLowerCase()) {
      case 'clear sky':
        setWeatherIcon(faSun);
        break;
      case 'few clouds':
      case 'scattered clouds':
      case 'broken clouds':
        setWeatherIcon(faCloudSun);
        break;
      case 'overcast clouds':
        setWeatherIcon(faCloud);
        break;
      case 'light rain':
      case 'moderate rain':
      case 'heavy intensity rain':
        setWeatherIcon(faCloudRain);
        break;
      case 'snow':
        setWeatherIcon(faSnowflake);
        break;
      case 'mist':
      case 'smoke':
      case 'haze':
      case 'dust':
      case 'fog':
      case 'sand':
      case 'volcanic ash':
      case 'squalls':
        setWeatherIcon(faWind);
        break;
      default:
        setWeatherIcon(faSun);
        break;
    }
  };

  return (
    <div className="navbar-weather text-[#838383] md:absolute">
      <div className="btn-group">
        <button type="button" className="btn navbar-icon grid grid-flow-col auto-cols-auto gap-x-2 items-center" data-bs-toggle="dropdown" aria-expanded="false">
          <div className="weather-icon">
            <FontAwesomeIcon icon={weatherIcon} className="text-2xl" />
          </div>
          <div className="md:grid grid-rows-2 text-sm font-medium">
            <div className="text-left weather-city-name">{selectedCity}</div>
            {weatherData && (
              <div>
                <p className="weather-temperature text-left">
                  {weatherData.main?.temp ? weatherData.main.temp.toFixed(1) : 'N/A'}°
                </p>
              </div>
            )}
          </div>
        </button>
        <div className="dropdown-menu dropdown-menu-end weather-dropdown cursor-pointer">
          <input className="weather-search-input text-black px-[10px]" placeholder="Şehir ara" type="text" value={searchTerm} onChange={handleSearchChange} />
          <ul className="text-black">
            {filteredCities.length > 0 ? (
              filteredCities.map((city, index) => (
                <li
                  key={index}
                  className="px-[10px] py-1 hover:bg-neutral-200"
                  data-city={city.toLowerCase()}
                  onClick={() => getWeather(city)}
                >
                  {city}
                </li>
              ))
            ) : (
              <li className="px-[10px]">Aradığınız şehir bulunamadı.</li>
            )}
          </ul>
        </div>
      </div>
      {/* {error && <div className="error-message text-red-500">{error}</div>} */}
    </div>
  );
}

export default WeatherWidget;
