import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./magazinepage.css";
import SideMenu from "../../components/sideMenu/SideMenu";
import TitleBar from "../../components/titleBar/TitleBar";
import Agustos2024 from "./issues/PSM-Agustos-2024.pdf";
import Eylul2024 from "./issues/PSM-Eylul-2024.pdf";
import Temmuz2024 from "./issues/PSM-Temmuz-2024.pdf";
import Haziran2024 from "./issues/PSM-Haziran-2024.pdf";
import Mayıs2024 from "./issues/PSM-Mayıs-2024.pdf";
import Ocak2023 from "./issues/PSM-Ocak-2023.pdf";
import Subat2023 from "./issues/PSM-Subat-2023.pdf";
import Mart2023 from "./issues/PSM-Mart-2023.pdf";
import Nisan2023 from "./issues/PSM-Nisan-2023.pdf";
import Mayıs2023 from "./issues/PSM-Mayıs-2023.pdf";
import Haziran2023 from "./issues/PSM-Haziran-2023.pdf";
import Temmuz2023 from "./issues/PSM-Temmuz-2023.pdf";
import Agustos2023 from "./issues/PSM-Agustos-2023.pdf";
import Eylul2023 from "./issues/PSM-Eylul-2023.pdf";
import Ekim2023 from "./issues/PSM-Ekim-2023.pdf";
import Kasım2023 from "./issues/PSM-Kasım-2023.pdf";
import Aralık2023 from "./issues/PSM-Aralık-2023.pdf";
import Ocak2024 from "./issues/PSM-Ocak-2024.pdf";
import Subat2024 from "./issues/PSM-Subat-2024.pdf";
import Mart2024 from "./issues/PSM-Mart-2024.pdf";
import Nisan2024 from "./issues/PSM-Nisan-2024.pdf";


const MagazinePage = () => {

    const magazineItems = [

        {
          id: 1,
          date: "08.22.2023",
          title: "PSM Eylül 2024",
          image: "https://www.psmmag.com/wp-content/uploads/2024/09/Adsiz-tasarim-1-300x229.png",
          file: Eylul2024,
        },
        {
          id: 2,
          date: "08.22.2023",
          title: "PSM Ağustos 2024",
          image: "https://www.psmmag.com/wp-content/uploads/2024/07/IMG_5425-300x229.jpeg",
          file: Agustos2024,
        },
        {
          id: 3,
          date: "08.22.2023",
          title: "PSM Temmuz 2024",
          image: "https://www.psmmag.com/wp-content/uploads/2024/07/IMG_5430-300x229.jpeg",
          file: Temmuz2024,
        },
        {
          id: 4,
          date: "08.22.2023",
          title: "PSM Haziran 2024",
          image: "https://www.psmmag.com/wp-content/uploads/2024/05/IMG_5431-300x229.jpeg",
          file: Haziran2024,
        },
        {
          id: 5,
          date: "08.23.2023",
          title: "PSM Mayıs 2024",
          image: "https://www.psmmag.com/wp-content/uploads/2024/04/IMG_4255-300x229.jpeg",
          file: Mayıs2024,
        },
        {
          id: 6,
          date: "08.24.2023",
          title: "PSM Nisan 2024",
          image: "https://www.psmmag.com/wp-content/uploads/2024/03/IMG_4018-300x229.jpeg",
          file: Nisan2024,
        },
        {
          id: 7,
          date: "08.25.2023",
          title: "PSM Mart 2024",
          image: "https://www.psmmag.com/wp-content/uploads/2024/02/psmmartdigital-300x229.png",
          file: Mart2024,
        },
        {
          id: 8,
          date: "08.26.2023",
          title: "PSM Şubat 2024",
          image: "https://www.psmmag.com/wp-content/uploads/2024/01/PSM-KAPAK-1-300x229.jpg",
          file: Subat2024,
        },
        {
          id: 9,
          date: "08.27.2023",
          title: "PSM Ocak 2024",
          image: "https://www.psmmag.com/wp-content/uploads/2024/01/PSM-Ocak-300x229.jpg",
          file: Ocak2024,
        },
        {
          id: 10,
          date: "08.28.2023",
          title: "PSM Aralık 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/11/Adsiz-tasarim-8-300x229.jpg",
          file: Aralık2023,
        },
        {
          id: 11,
          date: "08.22.2023",
          title: "PSM Kasım 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/10/PSM-1-300x229.jpg",
          file: Kasım2023,
        },
        {
          id: 12,
          date: "08.22.2023",
          title: "PSM Ekim 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/10/PSM-300x229.jpg",
          file: Ekim2023,
        },
        {
          id: 13,
          date: "08.22.2023",
          title: "PSM Eylül 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/09/PSM-dergi-kapak-1-300x229.jpg",
          file: Eylul2023,
        },
        {
          id: 14,
          date: "08.23.2023",
          title: "PSM Ağustos 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/07/22223-300x229.jpg",
          file: Agustos2023,
        },
        {
          id: 15,
          date: "08.24.2023",
          title: "PSM Temmuz 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/07/Untitled-pdf.jpg",
          file: Temmuz2023,
        },
        {
          id: 16,
          date: "08.25.2023",
          title: "PSM Haziran 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/06/psm-dergi-3-kapak-pdf.jpg",
          file: Haziran2023,
        },
        {
          id: 17,
          date: "08.26.2023",
          title: "PSM Mayıs 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/05/Sunu-4-pdf.jpg",
          file: Mayıs2023,
        },
        {
          id: 18,
          date: "08.27.2023",
          title: "PSM Nisan 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/04/Sunu-6-pdf.jpg",
          file: Nisan2023,
        },
        {
          id: 19,
          date: "08.28.2023",
          title: "PSM Mart 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/02/Sunu-8-pdf.jpg",
          file: Mart2023,
        },
        {
          id: 20,
          date: "08.22.2023",
          title: "PSM Şubat 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/01/Sunu-11-pdf.jpg",
          file: Subat2023,
        },
        {
          id: 21,
          date: "08.22.2023",
          title: "PSM Ocak 2023",
          image: "https://www.psmmag.com/wp-content/uploads/2023/01/psm-16_9_3-300x229.png",
          file: Ocak2023,
        },
    
    
    
      ];

    const [visibleItems, setVisibleItems] = useState(18);

    const showMoreItems = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + 9); 
    };

    const handleClick = (file) => {
        window.location.href = file;  
    };
    
    return (
        <div className='magazine-page-container'>
            <div className='magazine-page-top-container md:grid grid-cols-11 md:px-60'>
                <div className='magazine-page-top-left-container col-span-7 md:pr-[30px]'>
                <div className="mb-4 md:hidden"><TitleBar title="Dergi" /></div>
                    <div className="magazine-news-grid-container">
                        <div className="magazine-news-grid">
                            {magazineItems.slice(0, visibleItems).map((item) => (
                                <div
                                    key={item.id}
                                    className="magazine-news-item"
                                    onClick={() => handleClick(item.file)}
                                >
                                    <img src={item.image} alt={item.title} />
                                    <div className="magazine-news-content">
                                        <h3 className="title">{item.title}</h3>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='text-center'>
                        {visibleItems < magazineItems.length && (
                            <button onClick={showMoreItems} className="load-more-button magazine-page-load-more-button">
                                Daha Fazla Dergi Yükle
                            </button>
                        )}
                    </div>
                </div>
                <div className='magazine-page-top-right-container col-span-4 md:pl-[30px]'>
                    <SideMenu />
                </div>
            </div>
        </div>
    );
};

export default MagazinePage;
