import React, { useState, useEffect } from "react";
import "./sdcards.css";
import { Link } from 'react-router-dom';
import SliderCard from "../sliderCard/SliderCard";
import Trendnews from "../trendnews/trendnews"

const Card = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [videoItems, setVideoItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState(4);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.psmmag.com/news/video-news');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          setVideoItems(data.filter(item => item.isVideoNews));
        } else {
          throw new Error('Unexpected data structure');
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % videoItems.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [videoItems]);

  const showMoreItems = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + 9);
  };

  const handleMouseEnter = (index) => {
    setCurrentIndex(index);
     // Resim değiştikten sonra loading durumunu true yap
  };

  const handleImageLoad = () => {
    setLoading(false); // Resim yüklendikten sonra loading durumunu false yap
  };

  const currentNews = videoItems[currentIndex] || {};

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <div id="sdcrs" className="main-card">
        <div className="image-container">
          {loading && <div className="loading">Yükleniyor...</div>}
          <img
            src={currentNews.image}
            alt="Main"
            onLoad={handleImageLoad}
            style={{ display: loading ? "none" : "block" }}
          />
          <div className="category">
           
          <a href="/video-galeri" target="_blank" className="category-btn">
                  VİDEO GALERİ
                </a>
                
          </div>
          <h2 className="text-left">{currentNews.title}</h2>
          {/* <p>
            {currentNews.date}
            
          </p> */}
        </div>
        <div className="sub-cards">
          {videoItems.slice(0, visibleItems).map((item, index) => (
            <Link to={`/news/${item.slug}`}
              key={item._id}
              target="_blank"
              className={`sub-card `}
              onMouseEnter={() => handleMouseEnter(index)}
            >
              <img src={item.image} alt="Sub" />
              <div className="sub-card-text">
                <h3 style={{ textAlign: "left" }}>{item.title}</h3>
                <p style={{ textAlign: "left", fontSize: 12 }}>
                  {item.date}
                  {/* / {news.comments} Yorum */}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Card;
