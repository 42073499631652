import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("auth/forgot-password", { email });
      toast.success(response.data.message);
    } catch (error) {
      toast.error(
        error.response?.data?.error || "Şifre sıfırlama işlemi başarısız oldu"
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email">E-posta Adresiniz</label>
      <br />
      <input
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="login-modal-input"
        required
      />
      <br />
      <input
        className="login-modal-submit-btn"
        type="submit"
        value="Şifre Sıfırlama Bağlantısı Gönder"
      />
    </form>
  );
};

export default ForgotPasswordForm;