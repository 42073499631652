import React from "react";

const CategoryLinks = () => {
  return (
    <div className="flex flex-wrap gap-2 mt-4 text-white">
      <a
        href="/dergi"
        className=" p-2.5 text-base transition-all bg-[#f5878c] hover:scale-110"
      >
        Dergi
      </a>
      <a
        href="/gundem"
        className="p-2.5 text-base transition-all bg-[#f5878c] hover:scale-110"
      >
        Gündem
      </a>
      <a
        href="/banka"
        className="p-2.5 text-base transition-all bg-[#f5878c] hover:scale-110"
      >
        Banka
      </a>
      <a
        href="/fintek"
        className="p-2.5 text-base transition-all bg-[#f5878c] hover:scale-110"
      >
        Fintek
      </a>
      <a
        href="/atm-pos"
        className="p-2.5 text-base transition-all bg-[#f5878c] hover:scale-110"
      >
        ATM & Pos
      </a>

      <a
        href="https://www.youtube.com/@psmdergisi9390"
        className="p-2.5 text-base transition-all bg-[#f5878c] hover:scale-110"
      >
        PSM TV
      </a>
      <a
        href="/foto-galeri"
        className="p-2.5 text-base transition-all bg-[#f5878c] hover:scale-110"
      >
        Foto Galeri
      </a>
      <a
        href="/video-galeri"
        className="p-2.5 text-base transition-all bg-[#f5878c] hover:scale-110"
      >
        Video Galeri
      </a>
    </div>
  );
};

export default CategoryLinks;
