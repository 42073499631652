import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom'; 
import "./MagazinecontentPage.css";
import SideMenu from "../../components/sideMenu/SideMenu";
import CommentSection from "../../components/comments/CommentSection";
import axios from "axios";
import Flipbook from "../../components/Flipbook/Flipbook";
import Agustos2024 from "./issues/PSM-Agustos-2024.pdf";
import Eylul2024 from "./issues/PSM-Eylul-2024.pdf";
import Temmuz2024 from "./issues/PSM-Temmuz-2024.pdf";
import Haziran2024 from "./issues/PSM-Haziran-2024.pdf";
import Mayıs2024 from "./issues/PSM-Mayıs-2024.pdf";
import Ocak2023 from "./issues/PSM-Ocak-2023.pdf";
import Subat2023 from "./issues/PSM-Subat-2023.pdf";
import Mart2023 from "./issues/PSM-Mart-2023.pdf";
import Nisan2023 from "./issues/PSM-Nisan-2023.pdf";
import Mayıs2023 from "./issues/PSM-Mayıs-2023.pdf";
import Haziran2023 from "./issues/PSM-Haziran-2023.pdf";
import Temmuz2023 from "./issues/PSM-Temmuz-2023.pdf";
import Agustos2023 from "./issues/PSM-Agustos-2023.pdf";
import Eylul2023 from "./issues/PSM-Eylul-2023.pdf";
import Ekim2023 from "./issues/PSM-Ekim-2023.pdf";
import Kasım2023 from "./issues/PSM-Kasım-2023.pdf";
import Aralık2023 from "./issues/PSM-Aralık-2023.pdf";
import Ocak2024 from "./issues/PSM-Ocak-2024.pdf";
import Subat2024 from "./issues/PSM-Subat-2024.pdf";
import Mart2024 from "./issues/PSM-Mart-2024.pdf";
import Nisan2024 from "./issues/PSM-Nisan-2024.pdf";
import { FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp, FaFacebook, FaYoutube } from "react-icons/fa";



const MagazineContentPage = () => {

  const { id } = useParams();

  const magazineItems = [

    {
      id: 1,
      date: "08.22.2023",
      title: "PSM Eylül 2024",
      image: "https://www.psmmag.com/wp-content/uploads/2024/09/Adsiz-tasarim-1-300x229.png",
      file: "Eylul2024",
    },
    {
      id: 2,
      date: "08.22.2023",
      title: "PSM Ağustos 2024",
      image: "https://www.psmmag.com/wp-content/uploads/2024/07/IMG_5425-300x229.jpeg",
      file: "Agustos2024",
    },
    {
      id: 3,
      date: "08.22.2023",
      title: "PSM Temmuz 2024",
      image: "https://www.psmmag.com/wp-content/uploads/2024/07/IMG_5430-300x229.jpeg",
      file: "Temmuz2024",
    },
    {
      id: 4,
      date: "08.22.2023",
      title: "PSM Haziran 2024",
      image: "https://www.psmmag.com/wp-content/uploads/2024/05/IMG_5431-300x229.jpeg",
      file: "Haziran2024",
    },
    {
      id: 5,
      date: "08.23.2023",
      title: "PSM Mayıs 2024",
      image: "https://www.psmmag.com/wp-content/uploads/2024/04/IMG_4255-300x229.jpeg",
      file: "Mayıs2024",
    },
    {
      id: 6,
      date: "08.24.2023",
      title: "PSM Nisan 2024",
      image: "https://www.psmmag.com/wp-content/uploads/2024/03/IMG_4018-300x229.jpeg",
      file: "Nisan2024",
    },
    {
      id: 7,
      date: "08.25.2023",
      title: "PSM Mart 2024",
      image: "https://www.psmmag.com/wp-content/uploads/2024/02/psmmartdigital-300x229.png",
      file: "Mart2024",
    },
    {
      id: 8,
      date: "08.26.2023",
      title: "PSM Şubat 2024",
      image: "https://www.psmmag.com/wp-content/uploads/2024/01/PSM-KAPAK-1-300x229.jpg",
      file: "Subat2024",
    },
    {
      id: 9,
      date: "08.27.2023",
      title: "PSM Ocak 2024",
      image: "https://www.psmmag.com/wp-content/uploads/2024/01/PSM-Ocak-300x229.jpg",
      file: "Ocak2024",
    },
    {
      id: 10,
      date: "08.28.2023",
      title: "PSM Aralık 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/11/Adsiz-tasarim-8-300x229.jpg",
      file: "Aralık2023",
    },
    {
      id: 11,
      date: "08.22.2023",
      title: "PSM Kasım 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/10/PSM-1-300x229.jpg",
      file: "Kasım2023",
    },
    {
      id: 12,
      date: "08.22.2023",
      title: "PSM Ekim 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/10/PSM-300x229.jpg",
      file: "Ekim2023",
    },
    {
      id: 13,
      date: "08.22.2023",
      title: "PSM Eylül 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/09/PSM-dergi-kapak-1-300x229.jpg",
      file: "Eylul2023",
    },
    {
      id: 14,
      date: "08.23.2023",
      title: "PSM Ağustos 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/07/22223-300x229.jpg",
      file: "Agustos2023",
    },
    {
      id: 15,
      date: "08.24.2023",
      title: "PSM Temmuz 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/07/Untitled-pdf.jpg",
      file: "Temmuz2023",
    },
    {
      id: 16,
      date: "08.25.2023",
      title: "PSM Haziran 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/06/psm-dergi-3-kapak-pdf.jpg",
      file: "Haziran2023",
    },
    {
      id: 17,
      date: "08.26.2023",
      title: "PSM Mayıs 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/05/Sunu-4-pdf.jpg",
      file: "Mayıs2023",
    },
    {
      id: 18,
      date: "08.27.2023",
      title: "PSM Nisan 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/04/Sunu-6-pdf.jpg",
      file: "Nisan2023",
    },
    {
      id: 19,
      date: "08.28.2023",
      title: "PSM Mart 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/02/Sunu-8-pdf.jpg",
      file: "Mart2023",
    },
    {
      id: 20,
      date: "08.22.2023",
      title: "PSM Şubat 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/01/Sunu-11-pdf.jpg",
      file: "Subat2023",
    },
    {
      id: 21,
      date: "08.22.2023",
      title: "PSM Ocak 2023",
      image: "https://www.psmmag.com/wp-content/uploads/2023/01/psm-16_9_3-300x229.png",
      file: "Ocak2023",
    },



  ];

  const pdfFiles = {
    Agustos2024,
    Eylul2024,
    Temmuz2024,
    Haziran2024,
    Mayıs2024,
    Ocak2023,
    Subat2023,
    Mart2023,
    Nisan2023,
    Mayıs2023,
    Haziran2023,
    Temmuz2023,
    Agustos2023,
    Eylul2023,
    Ekim2023,
    Kasım2023,
    Aralık2023,
    Ocak2024,
    Subat2024,
    Mart2024,
    Nisan2024
  };

  const magazine = magazineItems.find((item) => item.id === parseInt(id));

  const shareUrl = window.location.href;

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnLinkedIn = () => {
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const pdfSrc = pdfFiles[magazine?.file];

  const currentIndex = magazineItems.findIndex((item) => item.id === parseInt(id));
  const prevMagazine = magazineItems[currentIndex + 1];
  const nextMagazine = magazineItems[currentIndex - 1];

  return (
    <div className="magazine-content-page-container">
      <div className="page-link-container md:px-60 items-center flex flex-wrap text-left text-base mt-6 font-medium">
        <a href="#" className="hover:underline">
          PSM Magazin
        </a>
        <span class="material-symbols-outlined">
chevron_right
</span>
        <a href="/dergi" className="hover:underline">
          Dergi
        </a>
        <span class="material-symbols-outlined">
chevron_right
</span>
        <a href="#" className="hover:underline">
          {magazine.title}
        </a>
      </div>

      <div className="magazine-content-page-header md:px-60 py-6">
        <h1 className="text-4xl font-bold mb-3">{magazine.title}</h1>
        <img src={magazine.image} alt="" />
        <div className="flex items-center text-gray-600">
          <div>
            <p className="text-xs">
              Yorum <span class="material-symbols-outlined text-sm">
chat_bubble
</span>
              {/* • Görüntülenme <i className="fa-solid fa-eye"></i> */}
            </p>
          </div>
        </div>
      </div>

      <div className="magazine-content-container  md:px-60">

        <div className="magazine-content-left-container md:px-[30px]">
          <div className="magazine-content">

          <div>
  
          <button
  onClick={() => window.location.href = pdfSrc}
  className="open-pdf-button md:hidden"
>
  Dergiyi Tarayıcıda Açmak İçin Tıklayınız
</button>

  
  <iframe
    className="magazine-iframe"
    title="PDF Viewer"
    src={pdfSrc}
    width="100%"
    height="800"
    style={{ border: "none" }}
  ></iframe>
</div>

            {/* <div>
              
              <iframe className="magazine-iframe"
      title="PDF Viewer"
      src={pdfSrc}
      width="100%"
      height="800"
      style={{ border: 'none' }}
    ></iframe>

            </div> */}

          </div>

          {/* <CommentSection /> */}

          <div className="share-card-container text-white mt-8">
            <div className="h-14 bg-[#360306] pl-6 flex items-center">
              <span class="material-symbols-outlined mr-4">
share
</span>
              <span className="font-bold mr-6">PAYLAŞ</span>

              <div className="share-link-container text-lg flex gap-2">
                <a onClick={shareOnFacebook} className="hover:bg-[#0867FF] hover:border-[#0867FF] cursor-pointer">
                  <FaFacebook />
                </a>
                <a onClick={shareOnTwitter} className="hover:bg-[#50ABF1] hover:border-[#50ABF1] cursor-pointer">
                  <FaTwitter />
                </a>
                <a onClick={shareOnLinkedIn} className="hover:bg-[#0274B3] hover:border-[#0274B3] cursor-pointer">
                  <FaLinkedin />
                </a>
                <a onClick={shareOnWhatsApp} className="hover:bg-[#26CA43] hover:border-[#26CA43] cursor-pointer">
                  <FaWhatsapp />
                </a>
              </div>
            </div>

            <div className="h-24 bg-[#C90913] px-6 py-3 grid grid-cols-2">
              <div className="text-left pr-6 border-r border-neutral-100">
                {prevMagazine ? (
                  <Link to={`/magazine-content/${prevMagazine.id}`}>
                    <span class="material-symbols-outlined">
arrow_back
</span>
                    <span className="next-previous-content md:mx-4 text-neutral-200">
                      Önceki Dergi
                    </span>
                    <h4 className="text-sm mt-2 truncate">{prevMagazine.title}</h4>
                  </Link>
                ) : (
                  <span className="text-neutral-100">Önceki Dergi Yok</span>
                )}
              </div>
              <div className="text-right pl-6">
                {nextMagazine ? (
                  <Link to={`/magazine-content/${nextMagazine.id}`}>
                    <span className="next-previous-content md:mx-4 text-neutral-200">
                      Sonraki Dergi
                    </span>
                    <span class="material-symbols-outlined">
arrow_forward
</span>
                    <h4 className="text-sm mt-2 truncate">{nextMagazine.title}</h4>
                  </Link>
                ) : (
                  <span className="text-neutral-100">Sonraki Dergi Yok</span>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="magazine-content-right-container col-span-4 md:pl-[30px]">
          <SideMenu />
        </div> */}
      </div>
    </div>
  );
};

export default MagazineContentPage;
