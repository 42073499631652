import HTMLFlipBook from 'react-pageflip';
import React, { useState, useRef } from "react";
import pdfFile from "../../assets/PSM-Agustos.pdf";
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;


const Pages = React.forwardRef((props, ref) => {
    return (
        <div className="demoPage" ref={ref}>
            <p>{props.children}</p>
        </div>
    );
});

Pages.displayName = "Pages";

function Flipbook(props) {
    const [numPages, setNumPages] = useState(1);
    const flipBookRef = useRef(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1); 
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function toggleFullscreen() {
        if (!isFullscreen) {
            if (flipBookRef.current.requestFullscreen) {
                flipBookRef.current.requestFullscreen();
            } else if (flipBookRef.current.mozRequestFullScreen) { /* Firefox */
                flipBookRef.current.mozRequestFullScreen();
            } else if (flipBookRef.current.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                flipBookRef.current.webkitRequestFullscreen();
            } else if (flipBookRef.current.msRequestFullscreen) { /* IE/Edge */
                flipBookRef.current.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
        }
        setIsFullscreen(!isFullscreen);
    }

    function zoomIn() {
        setZoomLevel(prevZoomLevel => Math.min(prevZoomLevel + 0.2, 2)); // Maksimum 2x zoom
    }

    function zoomOut() {
        setZoomLevel(prevZoomLevel => Math.max(prevZoomLevel - 0.2, 1)); // Minimum 1x zoom (orijinal boyut)
    }

    function handleMouseDown(e) {
        setIsDragging(true);
        setDragStart({ x: e.clientX, y: e.clientY });
    }

    function handleMouseMove(e) {
        if (isDragging && flipBookRef.current) {
            const dx = e.clientX - dragStart.x;
            const dy = e.clientY - dragStart.y;
            flipBookRef.current.scrollLeft -= dx;
            flipBookRef.current.scrollTop -= dy;
            setDragStart({ x: e.clientX, y: e.clientY });
        }
    }

    function handleMouseUp() {
        setIsDragging(false);
    }

    return (
        <div>
            <div className="flex gap-2 justify-center mb-4">
                <button onClick={toggleFullscreen} className='px-4 py-2 bg-[#C90913] text-white hover:bg-[#780006]'>
                    {isFullscreen ? <i className="fa-solid fa-compress"></i> : <i className="fa-solid fa-expand"></i>}
                </button>
                <button onClick={zoomIn} className='px-4 py-2 bg-[#C90913] text-white hover:bg-[#780006]'>
                    <i className="fa-solid fa-search-plus"></i>
                </button>
                <button onClick={zoomOut} className='px-4 py-2 bg-[#C90913] text-white hover:bg-[#780006]'>
                    <i className="fa-solid fa-search-minus"></i>
                </button>
            </div>
        <div
            ref={flipBookRef}
            className='w-full h-full flex flex-col gap-3 items-center overflow-hidden'
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
        >
            
            
            <HTMLFlipBook width={400 * zoomLevel} height={550 * zoomLevel}>
                {[...Array(numPages).keys()].map((pg) => (
                    <Pages key={pg} number={pg + 1}>
                        <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pg} width={400 * zoomLevel} renderAnnotationLayer={false} renderTextLayer={false} />
                        </Document>
                    </Pages>
                ))}
            </HTMLFlipBook>
        </div>
        </div>
    );
}

export default Flipbook;
