import React, { useEffect, useState } from 'react';
import "./adsmid.css";
import mbad1 from "./midbtmad1.jpg";
import mbad2 from "./midbtmad2.jpeg";
import qnb from "../trendnews/300x250.jpg";
import ads2 from "../trendnews/gazze.jpg";


const AdsMidBottom2 = () => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    // API'den reklamları çekme işlemi
    const fetchAds = async () => {
      try {
        const response = await fetch('https://api.psmmag.com/api/ads'); // Burada kendi API URL'inizi kullanın
        const data = await response.json();
        
        const filteredAds = data.filter(ad => 
          ad.title.includes("Trendsler ÜSTÜ KARE Banner") && ad.isActive === true
        );

        setAds(filteredAds);
      } catch (error) {
        console.error("Reklamlar çekilemedi:", error);
      }
    };

    fetchAds();
  }, []);

  const handleAdClick = async () => {
    if (ads && ads._id) {
      try {
        await fetch(`https://api.ekonomimanset.com/api/ads/${ads._id}/hit`, {
          method: "POST",
        });
      } catch (error) {
        console.error("Error incrementing hit count:", error);
      }
    }
  };

  return (
    <div>
      <div className='mid-bottom-ads-container2-web'>
      {ads.map((ad, index) => (
        <a key={index} href={ad.link} target="_blank" rel="noopener noreferrer" onClick={handleAdClick}>
          <img src={ad.imageURL} alt={ad.title} />
        </a>
      ))}
      </div>
    </div>
  )
}

export default AdsMidBottom2