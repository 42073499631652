import React, { useState } from "react";

const CommentSection = ({ comments, onCommentSubmit }) => {
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [notification, setNotification] = useState(""); // Bildirim durumu için state

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (author && content) {
      await onCommentSubmit({ author, content });
      setContent("");
      setAuthor("");
      setNotification("Yorumunuz onaya gönderildi"); // Yorum gönderildikten sonra bildirimi göster
      setTimeout(() => setNotification(""), 5000); // 5 saniye sonra bildirimi temizle

      // Eğer kullanıcı bilgileri kaydedilecekse, burada localStorage kullanabilirsiniz
      if (saveInfo) {
        localStorage.setItem("commentAuthor", author);
      }
    }
  };

  return (
    <div className="border-t my-3">
      <p className="text-2xl font-bold my-3">Yorum Yap</p>
      <form className="mb-6" onSubmit={handleSubmit}>
        <textarea
          className="w-full p-2 border border-gray-400  mb-3"
          rows="4"
          placeholder="Yorumunu Yaz"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        ></textarea>
        <input
          type="text"
          className="w-full p-2 border border-gray-400  mb-3"
          placeholder="Adınız"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          required
        />
        <div className="flex items-center mb-4">
          {/* <label className="text-sm">
            <input
              type="checkbox"
              className="mr-2"
              checked={saveInfo}
              onChange={(e) => setSaveInfo(e.target.checked)}
            />
            Bir dahaki sefere yorum yaptığımda kullanılmak üzere adımı, e-posta
            adresimi ve web site adresimi bu tarayıcıya kaydet.
          </label> */}
        </div>
        <button type="submit" className="bg-[#C90913] hover:bg-[#770006] text-white py-2 px-4 ">
          Yorumu Gönder
        </button>
        {notification && <p className="text-green-600 mt-2">{notification}</p>}{" "}
        {/* Bildirimi butonun altında göster */}
      </form>
      <div className="border-t pt-6">
        {comments.map((comment) => (
          <div key={comment._id} className="flex items-start mb-4">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg?20200418092106"
              alt="User Avatar"
              className="w-10 h-10 rounded-full mr-4"
            />
            <div>
              <p className="text-base font-semibold">
                <span className="text-gray-900">{comment.author}</span>
                <span className="text-gray-500 text-xs font-normal ml-2">
                  {new Date(comment.createdAt).toLocaleString("tr-TR", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </p>
              <p className="text-gray-800 my-2">{comment.content}</p>
              {/* <button className="text-red-500 text-sm mt-2">Yanıtla</button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentSection;
