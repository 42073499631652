import React from 'react'
import BigCardList from '../bigCards/BigCardList'
import SideMenu from '../sideMenu/SideMenu'
import Smallcards from "../../components/smallcards/smallcards";
import AdsMidBottom from '../ads/AdsMidBottom';
import AdsMidBottom2 from '../ads/AdsMidBottom2';
import Card from '../sliderDownCards/sdcards';
import Card2 from '../sliderDownCards/sdcards2';
import qnb from "../trendnews/300x250.jpg";
import ads2 from "../trendnews/gazze.jpg";
import "./adstrends.css";
import AdsMidBottom3 from '../ads/AdsMidBottom3';


const CardsAndMenu = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-[18px] mt-6 mb-6">
      <div className='col-span-2'>
      < AdsMidBottom /> <br />
        <div className="lg:hidden mobile-ads mb-2">
        <AdsMidBottom2/>
        <AdsMidBottom3/> 
        </div>
        <Smallcards />
        <div className='lg:grid grid-cols-2 gap-4 '>
        <Card />
        <Card2 />
        </div>
        </div>
        
        <div className="hidden lg:block">
          <AdsMidBottom2/>
          <br />
          <SideMenu />
        </div>
      </div>
  )
}

export default CardsAndMenu