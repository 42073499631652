import React, { useState, useEffect } from "react";
import "./dergiCarousel.css"; // Stil dosyanızı ekleyebilirsiniz
import Agustos2024 from "../../pages/MagazinePage/issues/PSM-Agustos-2024.pdf";
import Eylul2024 from "../../pages/MagazinePage/issues/PSM-Eylul-2024.pdf";
import Temmuz2024 from "../../pages/MagazinePage/issues/PSM-Temmuz-2024.pdf";
import Haziran2024 from "../../pages/MagazinePage/issues/PSM-Haziran-2024.pdf";
import Mayıs2024 from "../../pages/MagazinePage/issues/PSM-Mayıs-2024.pdf";
import Ocak2023 from "../../pages/MagazinePage/issues/PSM-Ocak-2023.pdf";
import Subat2023 from "../../pages/MagazinePage/issues/PSM-Subat-2023.pdf";
import Mart2023 from "../../pages/MagazinePage/issues/PSM-Mart-2023.pdf";
import Nisan2023 from "../../pages/MagazinePage/issues/PSM-Nisan-2023.pdf";
import Mayıs2023 from "../../pages/MagazinePage/issues/PSM-Mayıs-2023.pdf";
import Haziran2023 from "../../pages/MagazinePage/issues/PSM-Haziran-2023.pdf";
import Temmuz2023 from "../../pages/MagazinePage/issues/PSM-Temmuz-2023.pdf";
import Agustos2023 from "../../pages/MagazinePage/issues/PSM-Agustos-2023.pdf";
import Eylul2023 from "../../pages/MagazinePage/issues/PSM-Eylul-2023.pdf";
import Ekim2023 from "../../pages/MagazinePage/issues/PSM-Ekim-2023.pdf";
import Kasım2023 from "../../pages/MagazinePage/issues/PSM-Kasım-2023.pdf";
import Aralık2023 from "../../pages/MagazinePage/issues/PSM-Aralık-2023.pdf";
import Ocak2024 from "../../pages/MagazinePage/issues/PSM-Ocak-2024.pdf";
import Subat2024 from "../../pages/MagazinePage/issues/PSM-Subat-2024.pdf";
import Mart2024 from "../../pages/MagazinePage/issues/PSM-Mart-2024.pdf";
import Nisan2024 from "../../pages/MagazinePage/issues/PSM-Nisan-2024.pdf";
import PsmLogoVertical from "../../assets/psm-logo-vertical.png"

const BottomCarouselNews = [
  {
    id: 1,
    date: "08.22.2023",
    title: "PSM Eylül 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/09/Adsiz-tasarim-1-300x229.png",
    file: Eylul2024,
  },
  {
    id: 2,
    date: "08.22.2023",
    title: "PSM Ağustos 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/07/IMG_5425-300x229.jpeg",
    file: Agustos2024,
  },
  {
    id: 3,
    date: "08.22.2023",
    title: "PSM Temmuz 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/07/IMG_5430-300x229.jpeg",
    file: Temmuz2024,
  },
  {
    id: 4,
    date: "08.22.2023",
    title: "PSM Haziran 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/05/IMG_5431-300x229.jpeg",
    file: Haziran2024,
  },
  {
    id: 5,
    date: "08.23.2023",
    title: "PSM Mayıs 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/04/IMG_4255-300x229.jpeg",
    file: Mayıs2024,
  },
  {
    id: 6,
    date: "08.24.2023",
    title: "PSM Nisan 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/03/IMG_4018-300x229.jpeg",
    file: Nisan2024,
  },
  {
    id: 7,
    date: "08.25.2023",
    title: "PSM Mart 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/02/psmmartdigital-300x229.png",
    file: Mart2024,
  },
  {
    id: 8,
    date: "08.26.2023",
    title: "PSM Şubat 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/01/PSM-KAPAK-1-300x229.jpg",
    file: Subat2024,
  },
  {
    id: 9,
    date: "08.27.2023",
    title: "PSM Ocak 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/01/PSM-Ocak-300x229.jpg",
    file: Ocak2024,
  },
  {
    id: 10,
    title: "Diğer Sayılar",
    image: require("../../assets/psm-logo-vertical.png"),
    file: "/dergi",
  },
];

const handleClick = (file) => {
  window.location.href = file;  
};

function BottomCarousel() {
  const [startIndex, setStartIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  useEffect(() => {
    
    const mediaQuery820 = window.matchMedia("(max-width: 820px)");
    const mediaQuery768 = window.matchMedia("(max-width: 768px)");
    const mediaQuery576 = window.matchMedia("(max-width: 576px)");
    const mediaQuery430 = window.matchMedia("(max-width: 430px)");

    const handleResize = () => {
      if (mediaQuery430.matches) {
        setItemsPerPage(2);
      } else if (mediaQuery576.matches) {
        setItemsPerPage(2);
      } else if (mediaQuery768.matches) {
        setItemsPerPage(5);
      } else if (mediaQuery820.matches) {
        setItemsPerPage(5);
      } else {
        setItemsPerPage(6);
      }
      setStartIndex(0); 
    };

    
    handleResize();

    
    mediaQuery768.addEventListener("change", handleResize);
    mediaQuery576.addEventListener("change", handleResize);
    mediaQuery430.addEventListener("change", handleResize);

    
    return () => {
      mediaQuery768.removeEventListener("change", handleResize);
      mediaQuery576.removeEventListener("change", handleResize);
      mediaQuery430.removeEventListener("change", handleResize);
    };
  }, []);

  const handlePrev = () => {
    setStartIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : BottomCarouselNews.length - itemsPerPage
    );
  };

  const handleNext = () => {
    setStartIndex((prevIndex) =>
      prevIndex < BottomCarouselNews.length - itemsPerPage ? prevIndex + 1 : 0
    );
  };

  
  const visibleItems = BottomCarouselNews.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <div className="carouselBox">
      <h1 className="carouselTitle h2 w-full">DERGİLERİMİZ</h1>
      <div className="carousel-container container">
        <button className="carousel-button prev-button" onClick={handlePrev}>
        <span class="material-symbols-outlined pt-2">
chevron_left
</span>
        </button>
        <div className="carousel-content container">
          {visibleItems.map((item) => (
            <div key={item.id} className="carousel-card cursor-pointer">
              <a onClick={() => handleClick(item.file)} rel="noopener noreferrer" >
                <img src={item.image} alt="" />
                <p className="titleContent">{item.title}</p>
              </a>
            </div>
          ))}
          {/* <div  className="carousel-card cursor-pointer">
              <a href="/dergi" rel="noopener noreferrer" >
                <img src={PsmLogoVertical} alt="" />
                <p className="titleContent">Diğer Sayılar</p>
              </a>
            </div> */}
        </div>
        <button className="carousel-button next-button" onClick={handleNext}>
        <span class="material-symbols-outlined pt-2">
chevron_right
</span>
        </button>
      </div>
    </div>
  );
}

export default BottomCarousel;