import React, { useState, useRef } from 'react';
import "./profilePage.css";
import defaultProfilePic from './default-profile-pic.png'

const ProfilePage = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileSelect = () => {
        const file = fileInputRef.current.files[0];
        setSelectedFile(file);
    };

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    const months = [
        'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
        'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
    ];
    const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);

    const subCards = [
        {
            image:
                "https://ekonomimanset.com/wp-content/uploads/2024/07/isik-acma-kapatma-eylemi-740x420.webp",

            text: "CHP’nin ışık açma-kapatma eylemi ülke genelinde yapıldı",

            subText: "CHP Genel Başkanı Özgür Özel'in çağrısıyla başlatılan ışık açma-kapama eylemi yurt genelinde gerçekleştirildi. Eyleme CHP Genel Merkezi de katıldı.",

            link: "https://ekonomimanset.com/chpnin-isik-acma-kapatma-eylemi-ulke-genelinde-yapildi/",
        },
    ]

    const currentSubCards = subCards

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [error, setError] = useState("");
    const [error2, setError2] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password.length < 8) {
            setError("Şifreniz en az 8 karakter olmalıdır.");
            return;
        }
        if (password !== confirmPassword) {
            setError("Şifreler eşleşmiyor.");
            return;
        }

        e.preventDefault();
        if (newPassword.length < 8) {
            setError2("Şifreniz en az 8 karakter olmalıdır.");
            return;
        }
        if (newPassword !== confirmNewPassword) {
            setError2("Şifreler eşleşmiyor.");
            return;
        }
    };

    const [activeTab, setActiveTab] = useState('Profil Bilgilerim');
    const [activeTabIcon, setActiveTabIcon] = useState('fa-circle-user');

    const handleTabClick = (event, tabName, iconClass) => {
        setActiveTab(tabName);
        setActiveTabIcon(iconClass);
    };



    return (
        <div className='profile-page-container md:px-60'>
            <div className='profile-page-top-container  text-left'>

                <h1 className='text-3xl font-medium mb-2'>
                    <i className={`fa-solid ${activeTabIcon}`}></i> &nbsp; {activeTab}
                </h1>

                <p className='text-lg'>Sayın (FirstName) (LastName), profil bilgilerinizi güncel tutmanızı öneririz</p>
            </div>

            <div className='profile-page-bottom-container md:grid grid-cols-10 gap-4 mt-4'>
                <div className='profile-page-bottom-left-container col-span-3 md:pb-[200px]'>

                    <div className='profile-picture-container mt-4 px-2 text-center'>
                        <div className='profile-picture-div '>
                            {selectedFile ? (
                                <div>

                                    <img className='profile-picture' src={URL.createObjectURL(selectedFile)} alt="Profil Fotoğrafı" />
                                </div>
                            ) : (
                                <div>

                                    <img src={defaultProfilePic} className='profile-picture' alt="Varsayılan Profil Fotoğrafı" />
                                </div>
                            )}
                        </div>
                        <div>
                            <label htmlFor="file-upload" className="custom-file-upload p-2 px-4 flex items-center">
                            <span class="material-symbols-outlined mr-2">
account_circle
</span> Fotoğrafı Değiştir
                            </label>
                            <input id="file-upload" type="file" ref={fileInputRef} onChange={handleFileSelect} style={{ display: 'none' }} />
                        </div>
                    </div>

                    <div className='px-4 py-2'>

                        <ul class="nav nav-underline profile-page-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="true" onClick={(e) => handleTabClick(e, 'Profil Bilgilerim', 'fa-circle-user')}>
                                    <div className='grid grid-flow-col auto-cols-auto gap-x-2 text-left'>
                                        <div className='w-[30px]'>
                                        <span class="material-symbols-outlined">
person
</span>
                                        </div>
                                        <div className=' text-left'><span>Profil Bilgilerim</span></div>

                                    </div>
                                </button>
                            </li>
                        
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="likes-tab" data-bs-toggle="tab" data-bs-target="#likes-tab-pane" type="button" role="tab" aria-controls="likes-tab-pane" aria-selected="false" onClick={(e) => handleTabClick(e, 'Beğenilerim', 'fa-thumbs-up')}>
                                    <div className='grid grid-flow-col auto-cols-auto gap-x-2 text-left'>
                                        <div className='w-[30px]'>
                                        <span class="material-symbols-outlined">
thumb_up
</span>
                                        </div>
                                        <div className='text-left'><span>Beğenilerim</span></div>
                                    </div></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="favorites-tab" data-bs-toggle="tab" data-bs-target="#favorites-tab-pane" type="button" role="tab" aria-controls="favorites-tab-pane" aria-selected="false" onClick={(e) => handleTabClick(e, 'Favorilerim', 'fa-bookmark')}>
                                    <div className='grid grid-flow-col auto-cols-auto gap-x-2 text-left'>
                                        <div className='w-[30px]'>
                                        <span class="material-symbols-outlined">
bookmark
</span>
                                        </div>
                                        <div className=' text-left'><span>Favorilerim</span></div>
                                    </div></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="comments-tab" data-bs-toggle="tab" data-bs-target="#comments-tab-pane" type="button" role="tab" aria-controls="comments-tab-pane" aria-selected="false" onClick={(e) => handleTabClick(e, 'Yorumlarım', 'fa-comment')}>
                                    <div className='grid grid-flow-col auto-cols-auto gap-x-2 text-left'>
                                        <div className='w-[30px]'>
                                        <span class="material-symbols-outlined">
chat
</span>
                                        </div>
                                        <div className=' text-left'><span>Yorumlarım</span></div>
                                    </div></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="articles-tab" data-bs-toggle="tab" data-bs-target="#articles-tab-pane" type="button" role="tab" aria-controls="articles-tab-pane" aria-selected="false" onClick={(e) => handleTabClick(e, 'Yazılarım', 'fa-message')}>
                                    <div className='grid grid-flow-col auto-cols-auto gap-x-2 text-left'>
                                        <div className='w-[30px]'>
                                        <span class="material-symbols-outlined">
sticky_note_2
</span>
                                        </div>
                                        <div className=' text-left'><span>Yazılarım</span></div>
                                    </div></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings-tab-pane" type="button" role="tab" aria-controls="settings-tab-pane" aria-selected="false" onClick={(e) => handleTabClick(e, 'Ayarlar', 'fa-gear')}>
                                    <div className='grid grid-flow-col auto-cols-auto gap-x-2 text-left'>
                                        <div className='w-[30px]'>
                                        <span class="material-symbols-outlined">
settings
</span>
                                        </div>
                                        <div className=' text-left'><span>Ayarlar</span></div>
                                    </div></button>
                            </li>
                            <li class="nav-item text-left mt-1" role="presentation">
                                <button class="text-left" id="settings-tab" type="button" aria-controls="settings-tab-pane" aria-selected="false">
                                    <div className='grid grid-flow-col auto-cols-auto gap-x-2 text-left hover:text-black'>
                                        <div className='w-[30px]'>
                                        <span class="material-symbols-outlined">
logout
</span>
                                        </div>
                                        <div className=' text-left'><span>Çıkış Yap</span></div>
                                    </div></button>
                            </li>
                        </ul>

                    </div>

                </div>

                

                <div className='profile-page-bottom-right-container col-span-7 text-left px-8 py-4'>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">

                            <h3 className='profile-page-title1'>Bilgilerim</h3>

                            <form action="">

                                <div className='md:grid grid-cols-2 gap-8'>
                                    <div>
                                        <label className='profile-page-title2' for="firstname">Ad</label><br />
                                        <input className='profile-page-input' type="text" id="firstname" name="firstname" /><br /></div>
                                    <div>
                                        <label className='profile-page-title2' for="lastname">Soyad</label><br />
                                        <input className='profile-page-input' type="text" id="lastname" name="lastname" />
                                    </div>
                                </div>

                                <div className='md:grid grid-cols-2 gap-8'>
                                    <div>
                                        <label className='profile-page-title2' for="birthday">Doğum Tarihi</label><br />

                                        <div className='grid grid-flow-col auto-cols-auto gap-2'>
                                            <select className='profile-page-input' value={day} onChange={(e) => setDay(e.target.value)}>
                                                <option value="">Gün</option>
                                                {days.map((day) => (
                                                    <option key={day} value={day}>{day}</option>
                                                ))}
                                            </select>

                                            <select className='profile-page-input' value={month} onChange={(e) => setMonth(e.target.value)}>
                                                <option value="">Ay</option>
                                                {months.map((month, index) => (
                                                    <option key={index} value={index + 1}>{month}</option>
                                                ))}
                                            </select>

                                            <select className='profile-page-input' value={year} onChange={(e) => setYear(e.target.value)}>
                                                <option value="">Yıl</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year}>{year}</option>
                                                ))}
                                            </select>


                                        </div>

                                    </div>
                                    <div>
                                        <label className='profile-page-title2' for="email">E-Posta Adresi</label><br />
                                        <input className='profile-page-input' type="email" id="email" name="email" />
                                    </div>
                                </div>

                                <div className='text-right'>
                                    <input
                                        className="profile-page-submit-button"
                                        type="submit"
                                        value="Kaydet"
                                    ></input>
                                </div>
                            </form>

                            <hr className='mx-2 my-4 text-neutral-500' />

                            <h3 className='profile-page-title1'>Biyografim</h3>

                            <form action="">

                                <textarea
                                    className="profile-page-input-message"
                                    name="mesaj"
                                    rows="8"
                                    placeholder="Kendinizi bize kısaca tanıtın."
                                    required
                                ></textarea>

                                <div className='text-right'>
                                    <input
                                        className="profile-page-submit-button"
                                        type="submit"
                                        value="Kaydet"
                                    ></input>
                                </div>

                            </form>

                            <hr className='mx-2 my-4 text-neutral-500' />

                            <h3 className='profile-page-title1'>Sosyal Medya Hesaplarım</h3>

                            <form action="">

                                <div className='md:grid grid-cols-2 gap-8'>
                                    <div>
                                        <label className='profile-page-title2' for="facebook">Facebook</label><br />
                                        <input className='profile-page-input' type="text" id="facebook" name="facebook" /><br /></div>
                                    <div>
                                        <label className='profile-page-title2' for="x-twitter">X (Twitter)</label><br />
                                        <input className='profile-page-input' type="text" id="x-twitter" name="x-twitter" />
                                    </div>
                                </div>

                                <div className='md:grid grid-cols-2 gap-8'>
                                    <div>
                                        <label className='profile-page-title2' for="instagram">Instagram</label><br />
                                        <input className='profile-page-input' type="text" id="instagram" name="instagram" /><br /></div>
                                    <div>
                                        <label className='profile-page-title2' for="linkedin">LinkedIn</label><br />
                                        <input className='profile-page-input' type="text" id="linkedin" name="linkedin" />
                                    </div>
                                </div>

                                <div className='text-right'>
                                    <input
                                        className="profile-page-submit-button"
                                        type="submit"
                                        value="Kaydet"
                                    ></input>
                                </div>
                            </form>



                        </div>

                        <div class="tab-pane fade" id="followers-tab-pane" role="tabpanel" aria-labelledby="followers-tab" tabindex="0">
                            <h3 className='profile-page-title1'>Takipçilerim</h3>
                        </div>

                        <div class="tab-pane fade" id="following-tab-pane" role="tabpanel" aria-labelledby="following-tab" tabindex="0">
                            <h3 className='profile-page-title1'>Takip Ettiklerim</h3>
                        </div>

                        <div class="tab-pane fade" id="likes-tab-pane" role="tabpanel" aria-labelledby="likes-tab" tabindex="0">
                            <h3 className='profile-page-title1'>Beğenilerim</h3>

                            <div className={`sub-cards}`}>
                                {currentSubCards.map((card, index) => (
                                    <div className="profile-page-sub-card" key={index}>
                                        <img src={card.image} alt="Sub" className="profile-page-sub-card-image" />
                                        <div className="profile-page-sub-card-text">
                                            <a className='profile-page-sub-card-title' href={card.link}>{card.text}</a>

                                            <div className="profile-page-sub-card-text">{card.subText}</div>
                                        </div>

                                    </div>
                                ))}
                            </div>

                        </div>

                        <div class="tab-pane fade" id="favorites-tab-pane" role="tabpanel" aria-labelledby="favorites-tab" tabindex="0">
                            <h3 className='profile-page-title1'>Favorilerim</h3>
                        </div>

                        <div class="tab-pane fade" id="comments-tab-pane" role="tabpanel" aria-labelledby="comments-tab" tabindex="0">
                            <h3 className='profile-page-title1'>Yorumlarım</h3>
                        </div>

                        <div class="tab-pane fade" id="articles-tab-pane" role="tabpanel" aria-labelledby="articles-tab" tabindex="0">
                            <h3 className='profile-page-title1'>Yazılarım</h3>
                        </div>

                        <div class="tab-pane fade" id="settings-tab-pane" role="tabpanel" aria-labelledby="settings-tab" tabindex="0">

                            <h3 className='profile-page-title1'>Şifre Değişikliği</h3>

                            <form onSubmit={handleSubmit}>

                                <div className='md:grid grid-cols-2 gap-4'>
                                    <div>
                                        <label className='profile-page-title2' htmlFor="password">Şifreniz</label>
                                        <br />
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            className="profile-page-input"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className='profile-page-title2' htmlFor="confirm-password">
                                            Şifreniz (Tekrar)
                                        </label>
                                        <br />
                                        <input
                                            type="password"
                                            id="confirm-password"
                                            name="confirm-password"
                                            className="profile-page-input"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                        />
                                        <br />
                                        <div className='h-8'>
                                            {error && <p style={{ color: "red" }}>{error}</p>}</div>
                                    </div>
                                </div>

                                <div className='md:grid grid-cols-2 gap-4'>
                                    <div>
                                        <label className='profile-page-title2' htmlFor="new-password">Yeni Şifreniz</label>
                                        <br />
                                        <input
                                            type="password"
                                            id="new-password"
                                            name="new-password"
                                            className="profile-page-input"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className='profile-page-title2' htmlFor="new-confirm-password">
                                            Yeni Şifreniz (Tekrar)
                                        </label>
                                        <br />
                                        <input
                                            type="password"
                                            id="confirm-new-password"
                                            name="confirm-new-password"
                                            className="profile-page-input"
                                            value={confirmNewPassword}
                                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                                            required
                                        />
                                        <br />
                                        <div className='h-8'>
                                            {error2 && <p style={{ color: "red" }}>{error2}</p>}</div>
                                    </div>
                                </div>

                                <div className='text-right'>
                                    <input
                                        className="profile-page-submit-button"
                                        type="submit"
                                        value="Kaydet"
                                    ></input>
                                </div>
                            </form>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default ProfilePage