import React, { createContext, useState, useContext, useEffect } from "react";
import api, { setAuthToken } from "../services/api";

const AuthContext = createContext({
  isLoggedIn: false,
  user: null,
});

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    
    if (token) {
      setAuthToken(token);
      fetchUserProfile(token);
    }
  }, []);

  const fetchUserProfile = async (token) => {
    try {
      const response = await api.get("api/auth/profile");
      setUser(response.data);
      setIsLoggedIn(true);
      
    } catch (error) {
      console.error("Kullanıcı bilgileri alınamadı", error);
      if (error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const login = async (token) => {
    
    if (!token) {
      console.error("Login attempted with null or undefined token");
      return;
    }
    localStorage.setItem("token", token);
    setAuthToken(token);
    await fetchUserProfile(token);
  };

  const logout = () => {
    
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    setUser(null);
    setAuthToken(null);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};